import React, { useMemo } from "react"
import FeaturedBlog from "../../components/FeaturedBlog/FeaturedBlog"
import BlogHeader from "../../components/BlogHeader/BlogHeader"
import Author from "../../components/Author/Author"
import Share from "../../components/Share/Share"
import ZigZag from "../../components/ZigZag/ZigZag"
import Contact from "../../components/Contact/Contact"
import { Layout } from "../../layouts"
import readingTime from "reading-time"
import "./ArticleDetail.scss"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import { graphql } from "gatsby"
import PostBody from "../../components/PostBody/PostBody"
import SEO from "../../components/Seo"
import { localizeDataNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const ZIGZAG_IMAGE_SIDE_START = "left"

const ArticleDetailTemplate = ({
  pageContext: { blogPost, routePrefix, lang, companies },
  data,
  location,
}) => {
  const { allDzemoApiCompanies, allDzemoApiZigzags } = useMemo(
    () => localizeDataNodes(data, lang),
    [data, lang]
  )
  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )
  const blogTitleCapitalized = capitalizeFirstLetter(blogPost[lang].name)

  return (
    <Layout
      location={location}
      lang={lang}
      pageData={blogPost}
      routePrefix={routePrefix}
      companies={companies}
    >
      <SEO
        title={blogTitleCapitalized}
        canonical={location.pathname}
        data={blogPost[lang]}
      />
      <div className="article-detail-page">
        <FeaturedBlog data={blogPost[lang]} />
        <BlogHeader
          author={blogPost[lang].profile}
          company={allDzemoApiCompanies}
          createdAt={blogPost[lang].created_at}
          readingTime={readingTime(blogPost[lang].body)}
        />
        <PostBody data={blogPost[lang]} className="blog-post-body" />
        <Share data={allDzemoApiCompanies[0]} />
        {blogPost[lang].profile && <Author data={blogPost[lang].profile} />}
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {blogPost[lang].zigzags.map((zigzag, index) => {
            const { id } = zigzag

            return (
              <ZigZag
                key={id}
                data={{
                  ...zigzag,
                  image: zigzag.image[0],
                  sideOfImage: zigzagImageSides[index],
                }}
              />
            )
          })}
        </section>
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{ message: "Your message has been sent!", type: "error" }}
          />
        </section>
      </div>
    </Layout>
  )
}
export default ArticleDetailTemplate

export const query = graphql`
  query articleDetailQuery($blogId: Int!) {
    allDzemoApiCompanies(limit: 1) {
      nodes {
        en {
          facebook
          instagram
          twitter
        }
        de {
          facebook
          instagram
          twitter
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { blogs: { elemMatch: { id: { eq: $blogId } } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }
  }
`
